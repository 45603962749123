<template>
  <div>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              width="500"
    >

      <v-card :disabled="btnLoading"
              :loading="btnLoading">

        <v-card-title>
          Prolonger
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <v-menu v-model="menuStartDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="form.start_date"
                            :error-messages="errors.start_date"
                            :min="dateNow"
                            append-icon="mdi-calendar"
                            label="Date début *"
                            readonly
                            v-bind="attrs"
                            v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="form.start_date"
                           :min="dateNow"
                           @input="[menuStartDate = false,form.end_date = null]"
            ></v-date-picker>
          </v-menu>

          <v-menu v-model="menuEndDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="form.end_date"
                            :disabled="!form.start_date"
                            :error-messages="errors.end_date"
                            append-icon="mdi-calendar"
                            label="Date de fin *"
                            readonly
                            v-bind="attrs"
                            v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="form.end_date"
                           :min="form.start_date"
                           @input="menuEndDate = false"
            ></v-date-picker>
          </v-menu>

        </v-card-text>


        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn :loading="btnLoading"
                 color="primary"
                 depressed
                 type="submit"
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>

  </div>
</template>

<script>

import {HTTP} from "@/http-common";
import moment from "moment";

export default {
    data() {
        return {
            dialog: false,
            dateNow: moment().format('YYYY-MM-DD'),
            menuStartDate: false,
            menuEndDate: false,
            btnLoading: false,
            form: {
                id: null,
                start_date: null,
                start_time: null,
            },
            errors: {},
            promotion: {
                status: {}
            }
        }
    },
    methods: {
        open(promotion) {
            this.dialog = true
            this.errors = {}
            this.promotion = promotion
            this.form = {
                id: promotion.id,
                start_date: promotion.start_date,
                end_date: promotion.end_date,
            }
        },
        close() {
            this.dialog = false
        },
        save() {
            this.errors = {}
            let url = '/v1/promotions/prolong'
            let data = this.form
            this.btnLoading = true
            HTTP.post(url, data).then(() => {
                this.btnLoading = false
                this.$successMessage = "Cette promotion a été prolongée avec succès."
                this.close()
                this.$emit('refresh')
            }).catch(err => {
                this.btnLoading = false
                this.errors = err.response.data.errors
                this.$errorMessage = "Une erreur s'est produite veuillez corriger."
                console.log(err)
            })
        },

    }
}
</script>

